import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import Member from "../../../media/userIcon.png";

import {
  useAcceptCommunityMutation,
  useGetCommunityQuery,
  useRejectCommunityMutation,
  useUpdateCommunityMemberRoleMutation,
} from "../../../../redux/api/Community/communityApi";
import { Btn, Button, RenderIf } from "../../../components";
import { useFormik } from "formik";
import "./MemberList.scss";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});
const initialValues = {
  communityId: "",
  siteUserId: "",
};

export default function MemberList(props) {
  const { onClose, id } = props;

  const {
    data: communtyDetailedInfo,
    isSuccess: detailedInfo,
    refetch: refetchDetailedInfo,
  } = useGetCommunityQuery(id);
  const [
    rejectMember,
    { isSuccess: rejectSuccess, isLoading: rejectLoading },
  ] = useRejectCommunityMutation();
  const [
    acceptMember,
    { isSuccess: acceptSuccess, isLoading: acceptLoading },
  ] = useAcceptCommunityMutation();
  const [
    updateMember,
    { isLoading: memberLoading, isSuccess: memberSuccess },
  ] = useUpdateCommunityMemberRoleMutation();
  const classes = useStyles();
  const form = useRef(null);
  const [userId, setUserId] = useState();
  const handleAccept = (userId) => {
    setUserId(userId);
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (values.submitType === "POST1") {
        const apply = new FormData(form.current);
        apply.append("siteUserId", userId);
        apply.append("communityId", id);

        acceptMember(apply);
      } else if (values.submitType === "POST2") {
        const reject = new FormData(form.current);
        reject.append("siteUserId", userId);
        reject.append("communityId", id);

        rejectMember(reject);
      } else if (values.submitType === "POST3") {
        const updateMemberData = new FormData();
        updateMemberData.append("communityId", id);
        updateMemberData.append("siteUserIds", userId);
        updateMemberData.append("role", 1);
        updateMember(updateMemberData);
      } else if (values.submitType === "POST4") {
        const updateMemberData = new FormData();
        updateMemberData.append("communityId", id);
        updateMemberData.append("siteUserIds", userId);
        updateMemberData.append("role", 2);
        updateMember(updateMemberData);
      }

      resetForm();
    },
  });
  useEffect(() => {
    if (acceptSuccess || rejectSuccess || memberSuccess) {
      refetchDetailedInfo();
    }
  }, [acceptSuccess, rejectSuccess, memberSuccess]);

  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">{communtyDetailedInfo?.title}</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <div className="memberList">
          <RenderIf
            condition={communtyDetailedInfo?.communitySiteUsers?.length}
            renderElse={<AlertComponent variant="info" message="Not Found" />}
          >
            {communtyDetailedInfo?.communitySiteUsers?.map((c) => (
              <div key={c?.id} className="memberListItem">
                <div className="memberListItemInner">
                  <div className="d-flex">
                    <div className="memberListItemLeft">
                      <img
                        alt="user img"
                        src={
                          c?.profilePhotoUrl
                            ? `${REACT_APP_BASE_PHOTO_URL_MAIN}${c?.profilePhotoUrl}`
                            : Member
                        }
                      />
                    </div>
                    <div className="memberListItemRight">
                      <h4>{c?.siteUserName}</h4>
                      <p>{c?.siteUserPosition}</p>
                    </div>
                  </div>
                  <form onSubmit={formik.handleSubmit} ref={form}>
                    <div className="buttons d-flex mt-3">
                      <RenderIf condition={c.status === 2}>
                        <Button
                          className="font_14"
                          background="#4C5359"
                          onClick={() =>
                            formik.setFieldValue("submitType", "POST2")
                          }
                          type="submit"
                        >
                          Rejected
                        </Button>
                      </RenderIf>
                      <RenderIf condition={c.status === 0}>
                        <Button
                          type="submit"
                          className="font_14"
                          background="#057953"
                        >
                          Apply
                        </Button>
                      </RenderIf>
                      <RenderIf
                        condition={c.status === 3 && c.role === "Member"}
                      >
                        <button
                          onClick={() => {
                            handleAccept(c?.siteUserId);
                          }}
                          className="innerButton btn btn-warning"
                        >
                          Accepted
                        </button>
                        <Btn
                          className="font_16 mx-3"
                          border="light"
                          onClick={() => {
                            handleAccept(c?.siteUserId);
                            formik.setFieldValue("submitType", "POST3");
                          }}
                          type="submit"
                        >
                          Make admin
                        </Btn>
                      </RenderIf>
                      <RenderIf
                        condition={c?.status === 1 && c?.role === "Member"}
                      >
                        <Btn
                          className="left font_16"
                          border="glassy"
                          onClick={() => {
                            handleAccept(c?.siteUserId);
                            formik.setFieldValue("submitType", "POST1");
                          }}
                          type="submit"
                        >
                          Accept
                        </Btn>
                        <Btn
                          className="font_16 mx-3"
                          border="light"
                          onClick={() => {
                            handleAccept(c?.siteUserId);
                            formik.setFieldValue("submitType", "POST2");
                          }}
                          type="submit"
                        >
                          Delete
                        </Btn>
                      </RenderIf>
                      <RenderIf
                        condition={c.role === "Manager" && c.status === 1}
                      >
                        <button
                          className="btn btn-danger"
                          border="light"
                          type="submit"
                        >
                          Admin
                        </button>
                        <Btn
                          className="font_16 mx-3"
                          border="light"
                          onClick={() => {
                            handleAccept(c?.siteUserId);
                            formik.setFieldValue("submitType", "POST4");
                          }}
                          type="submit"
                        >
                          Make member
                        </Btn>
                      </RenderIf>
                      <RenderIf
                        condition={c.role === "Manager" && c.status === 3}
                      >
                        <button
                          className="btn btn-danger"
                          border="light"
                          type="submit"
                        >
                          Admin
                        </button>
                        <Btn
                          className="font_16 mx-3"
                          border="light"
                          onClick={() => {
                            handleAccept(c?.siteUserId);
                            formik.setFieldValue("submitType", "POST4");
                          }}
                          type="submit"
                        >
                          Make member
                        </Btn>
                      </RenderIf>
                    </div>
                  </form>
                </div>
              </div>
            ))}
          </RenderIf>
        </div>
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
