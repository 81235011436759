import React, { useEffect, useState } from "react";
import DataTables from "./components/DashboardTables";
import Filter from "./modals/Filter";
import View from "./modals/View";
import Edit from "./modals/Edit";
import Delete from "./modals/Remove";
import DeleteAll from "./modals/RemoveAll";
import Create from "./modals/Create";
import ChangePassword from "./modals/ChangePassword";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import { updateObject } from "../../../Helpers/updateObject";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import Snackbar from "@material-ui/core/Snackbar";
import {
  useGetUsersQuery,
  useLazyGetExportUsersQuery,
  useUpdateUserStatusMutation,
} from "../../../redux/api/Auth/Users/usersApi";
import { RenderIf } from "../../../app/components";
import Upload from "./modals/Upload";
const { REACT_APP_BASE_URL } = process.env;

export function Dashboard() {
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showRemoveAll, setShowRemoveAll] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showFilter, setShowFilter] = React.useState(false);

  function handleChange() {
    setShowFilter((key) => !key);
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);

  const [sortField, setSortField] = useState("id");
  const [orderBy, setOrderBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [skip, setSkip] = useState(0);
  const [take] = useState(10);
  const [userId, setUserId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const permissions = user?.user?.permissions;
  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const { data, refetch, isLoading } = useGetUsersQuery({
    skip: page * rowsPerPage || 0,
    take: rowsPerPage,
    field: sortField,
    order: orderBy,
    name: filterInputs,
  });

  const [
    getExportUsers,
    { isLoading: exportLoading },
  ] = useLazyGetExportUsersQuery();
  const [updateStatus, { isSuccess }] = useUpdateUserStatusMutation();

  const onUpdateUserStatus = (id, statusType) => {
    let status = !statusType;

    updateStatus({
      id,
      status,
    });
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });
    setFilterInputs(updateFiltersInput);
    setSkip(0);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const handleDowload = () => {
    getExportUsers({
      skip: page * rowsPerPage,
      take: rowsPerPage,
      field: "id",
      order: false,
      name: filterInputs,
    });
    // if (exportSuccess) {
    window.open(
      REACT_APP_BASE_URL +
        `siteUser/excelExport?${skip ? `&Skip=${skip}` : ""}${
          take ? `&Take=${take}` : ""
        }${orderBy ? `&OrderBy=${orderBy}` : ""}${
          filterInputs?.firstName ? `&FirstName=${filterInputs?.firstName}` : ""
        }${
          filterInputs?.lastName ? `&LastName=${filterInputs?.lastName}` : ""
        }${filterInputs?.email ? `&Email=${filterInputs?.email}` : ""}`
    );
    // }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div className="row bg-white d-flex justify-content-center rounded h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Site Users</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Hide filter" : "Show filter"}
          className="ml-auto mb-0"
        />
        <button type="button" className="btn btn-info" onClick={handleDowload}>
          Get excel
          <RenderIf condition={exportLoading}>
            <span className="mx-3 spinner spinner-white"></span>
          </RenderIf>
        </button>
        <button
          type="button"
          className="btn btn-success mx-2"
          onClick={() => setShowUpload(true)}
        >
          Upload file
        </button>
      </div>
      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data?.data && !isLoading}>
        <DataTables
          setSortField={setSortField}
          setOrderByTable={setOrderBy}
          sortField={sortField}
          orderBy={orderBy}
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenRemoveAll={setShowRemoveAll}
          setOpenCreate={setShowCreate}
          setOpenChangePassword={setShowChangePassword}
          setOpenUpdatePassword={setShowUpdatePassword}
          rows={data?.data}
          rowsPerPage={rowsPerPage}
          permissions={permissions}
          page={page}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          count={data?.totalCount}
          onUpdateUserStatus={onUpdateUserStatus}
          setUserId={setUserId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          // roles={roles}
        />
      </RenderIf>

      <RenderIf condition={showRemoveAll}>
        <DeleteAll
          deleteUsersIds={deleteUsersIds}
          setDeleteUsersIds={setDeleteUsersIds}
          show={showRemoveAll}
          onHide={setShowRemoveAll}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          refetch={refetch}
        />
      </RenderIf>
      <RenderIf condition={showCreate}>
        <Create
          show={showCreate}
          onHide={setShowCreate}
          refetch={refetch}
          onUsers={data}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          // roles={roles}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <Edit
          id={userId}
          show={showEdit}
          onHide={setShowEdit}
          refetch={refetch}
          // roles={roles}
          filterInputs={filterInputs}
        />
      </RenderIf>

      <RenderIf condition={showChangePassword}>
        <ChangePassword
          show={showChangePassword}
          onHide={setShowChangePassword}
          handleClick={handleClick}
          id={userId}
        />
      </RenderIf>

      <RenderIf condition={showFilter}>
        <Filter
          show={showFilter}
          onHide={handleChange}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
        />
      </RenderIf>
      <RenderIf condition={showRemove}>
        <Delete
          id={userId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showUpload}>
        <Upload
          id={userId}
          show={showUpload}
          onHide={setShowUpload}
          refetch={refetch}
        />
      </RenderIf>
      <RenderIf condition={showView}>
        <View id={userId} open={showView} onClose={toggleDrawer(false)} />
      </RenderIf>
      <RenderIf condition={open}>
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          {/* <Alert onClose={handleClose} severity="success">
                        Şifrə uğurla dəyişdirildi!
                    </Alert> */}
        </Snackbar>
      </RenderIf>
    </div>
  );
}
